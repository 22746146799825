<template>
  <div v-if="!loading">
    <setting-item
      name="Doctoralia"
      description="Integração com a plataforma Doctoralia"
      info="Para utilizar essa integração é necessário ser cliente Doctoralia"
      type="switch"
      :value="form.doctoralia.enabled"
      :action-icon="['fal', 'cog']"
      :action-fn="() => { doctoraliaModal.show = true }"
      :action-disabled="form.doctoralia.enabled === false"
      @change="updateValue('enabled', $event, 'doctoralia')"
    ></setting-item>

    <setting-item
      name="Logic"
      description="Integração de laudos, visualizador DICOM e protocolos de exames"
      info="Para utilizar essa integração é necessário ser cliente da Logic"
      type="switch"
      :value="false"
      :action-icon="['fal', 'cog']"
      :soon="true"
    ></setting-item>

    <dx-modal v-model="doctoraliaModal.show" title="Doctoralia">
      <div class="form-group">
        <label for="client-id" class="form-label">Client ID</label>
        <input
          class="form-input"
          id="client-id"
          type="text"
          v-model="form.doctoralia.clientId"
          placeholder="Client ID"
        >
      </div>
      <div class="form-group">
        <label for="client-secret" class="form-label">Client Secret</label>
        <input
          class="form-input"
          id="client-secret"
          type="text"
          v-model="form.doctoralia.clientSecret"
          placeholder="Client Secret"
        >
      </div>
      <div class="form-group">
        <label for="client-webhook" class="form-label">Endereço Webhook</label>
        <div class="input-group">
          <input
            class="form-input"
            id="client-webhook"
            type="text"
            :value="webhookUrl"
            readonly="readonly"
          >
          <button
            class="btn input-group-btn btn-info btn-icon tooltip"
            @click="copyWebhookToClipboard"
            :disabled="!webhookUrl"
            data-tooltip="Copiar"
          ><fa-icon :icon="['fal', 'copy']"></fa-icon></button>
        </div>
      </div>
      <template v-slot:footer>
        <button
          class="btn btn-primary"
          :class="{ loading: saving }"
          :disabled="saving"
          @click="updateGroup('doctoralia')"
        >Salvar</button>
        <button class="btn ml-2" @click="doctoraliaModal.show = false">Sair</button>
      </template>
    </dx-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mergeFrom } from '@/helpers/object';
import SettingItem from '../SettingItem.vue';

export default {
  props: {
    loading: {
      type: Boolean,
    },
    settings: {
      type: Object,
    },
  },
  components: {
    SettingItem,
  },
  data() {
    return {
      saving: false,
      form: this.blankForm(),
      doctoraliaModal: {
        show: false,
      },
    };
  },
  watch: {
    loading() {
      this.updateForm();
    },
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
    customerId() {
      return this.user && this.user.facility ? this.user.facility.customerId : null;
    },
    isSet() {
      return this.form.doctoralia.clientId && this.form.doctoralia.clientSecret;
    },
    webhookUrl() {
      return this.isSet ? `https://api.stenci.pro/hook/doctoralia/${this.customerId}` : '--';
    },
  },
  methods: {
    updateForm() {
      this.form = mergeFrom(this.form, this.settings);
    },
    async updateGroup(group) {
      await this.update({
        [group]: this.form[group],
      });
    },
    async updateValue(key, value, group) {
      await this.update({
        [group]: {
          [key]: value,
        },
      });
      this.form[group][key] = value;
    },
    async update(data = null) {
      this.saving = true;
      try {
        await this.$http.put('/facility-settings', data || this.form);
        this.$toast.success('Configuração salva com sucesso');
      } catch (e) {
        this.$toast.error(e);
      }
      this.saving = false;
    },
    copyWebhookToClipboard() {
      navigator.clipboard.writeText(this.webhookUrl);
      this.$toast.success('Endereço copiado');
    },
    blankForm() {
      return {
        doctoralia: {
          enabled: true,
          clientId: '',
          clientSecret: '',
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/_variables.scss";
</style>
